.end_link {
    padding: 20px;
    color: var(--primary);
    font-weight: bold;
    text-decoration: none;
    display: block;
    margin: 10px;
    font-size: 40px;
}

.contact_wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.end_link:hover {
    color: #212121;
    transition-duration: 0.8s;
    background-color: var(--primary);

    ;
}

.contact_text {
    font-size: 40px;
    height: 400px;
}

.contact_text a {
    text-decoration: none;
    font-weight: bold;
    transition: 0.5s;
    /* padding: 3px; */
}

.contact_text a:hover {
    transition: 0.5s;
    filter: saturate(0%);
}

@media only screen and (max-width: 1100px) {
    .end_link {

        font-size: 20px;
        display: inline;

        width: 100%;
    }

    .contact_text {
        font-size: 30px;
        height: auto;
        margin: 30px;
    }

    .contact_wrapper {
        flex-direction: column-reverse;
        height: auto;
    }
}

@media only screen and (max-width: 600px) {

    .end_link {

        font-size: 15px;
        float: left;
        text-align: center;
        margin: 0px;
        padding: 10px;
    }
}