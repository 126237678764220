.landing_link {
    padding: 20px;

    font-weight: bold;
    text-decoration: none;
    background-color: none;
    position: relative;
}


.landing_link:hover {}

.landing_link:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 10px;
    left: 50%;
    position: absolute;
    background: var(--primary);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.landing_link:hover:after {
    width: 100%;
    left: 0;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    background: rgb(156, 156, 156) !important;
}