.study_wrapper h1 {
    color: #212121;

    text-align: center;
    padding: 20px;
    margin-top: 20px;
}

.study_wrapper {
    min-height: 100vh;
}

.study_card_wrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.study_card {
    max-width: 450px;
    margin: 20px;
    padding: 20px;
    background-color: #212121;

}

.study_card h2 {

    font-size: 22px;
    float: left;
    filter: saturate(90%);
}

.study_card p {
    float: left;
    filter: brightness(80%) saturate(70%);
}

/* 
@media only screen and (min-width: 1500px) {
    .study_card:hover {
        transform: scale(1.2);
        transition: 0.2s;
        border: 1px solid #212121ce;
    }
} */

.study_card a {
    font-size: 20px;
    text-decoration: none;
    float: right;
    color: rgb(131, 130, 130);
    transition: 0.2s;
}

.study_card a:hover {
    transform: scale(1.2);
    transition: 0.2s;
}

.web_linka {
  
    text-decoration: none;
    font-weight: bold;;
    color: rgb(211, 211, 211);
    transition: 0.2s;
}
.web_linka:hover {
    transform: scale(1.2);
    transition: 0.2s;
    filter: brightness(0.7);
}

.lang_spec {
    filter: saturate(50%);
    font-weight: bold;
    float: left;
    height: 100%;
    margin-right: 20px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.example_img{
    width: 40%;
}
@media only screen and (max-width: 1100px) {
    .example_img{
        width: 80%;
    }
}

.study_card_bp {
    /* max-width: 600px; */

}

.study_card_bp h2 {}