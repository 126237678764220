.sections_holder {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh;

}

.technology_section {
    margin: 20px;
    padding: 20px;
    margin-right: 50px;
    margin-left: 50px;


}

.technology_section h1 {
    font-size: 40px;

}

.technology_section p {
    font-size: 1.6vw;
    margin-top: 10px;
    font-weight: lighter;
}



@media only screen and (max-width: 1100px) {
    .sections_holder {
        justify-content: center;
    }

    .technology_section {
        margin: 20px;
        width: 80%;
        border-top: none;
        border-bottom: #fdd835 20px solid;
    }

    .technology_section h1 {
        font-size: 50px;
    }

    .technology_section p {
        font-size: 30px;
    }
}

/* 

@media only screen and (max-height: 800px) and (max-width: 800px) {
    .technology_section {
        margin: 5px;
        padding: 5px;

    }
} */