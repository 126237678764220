.palette_wraper {
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translate(0%, -50%);
}




.palette_box {
    transition: 0.1s;
    width: 30px;
    height: 30px;
}

.palette_box:hover {
    transition: 0.1s;
    transform: scale(1.2);
    cursor: pointer;
}


@media only screen and (max-width: 1100px) {
    .palette_wraper {
        position: fixed;
        top: 20px;
        right: 50%;
        transform: translate(+50%, 0%);
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .palette_box {
        float: left;
        width: 100%;
    }
}